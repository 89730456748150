import React, { useContext } from "react";

import { CpCheckbox, CpCheckboxProps } from "../cp-checkbox/cp-checkbox";
import { CpCheckboxGroupContext } from "./cp-checkbox-group-context";
import { CheckboxGroupVal, CheckboxGroupValObj } from "./cp-checkbox-group";

export type CpCheckboxItemProps = CpCheckboxProps & {
  id: CheckboxGroupVal;
};

export function CpCheckboxGroupItem(props: CpCheckboxItemProps) {
  const { children, id, disabled, ...rest } = props;
  const {
    value,
    disabled: parentDisabled,
    onBlur,
    onChange,
  } = useContext(CpCheckboxGroupContext);
  const isChecked =
    typeof id === "object"
      ? ((value ?? []) as CheckboxGroupValObj[]).findIndex(
          (val) => val.id === id.id,
        ) >= 0
      : (value as CheckboxGroupVal[])?.includes(id);
  return (
    <CpCheckbox
      disabled={typeof parentDisabled === "boolean" ? parentDisabled : disabled}
      onBlur={onBlur}
      {...rest}
      checked={isChecked}
      onChange={() => onChange?.(id)}
    >
      {children}
    </CpCheckbox>
  );
}
