import React, {
  forwardRef,
  useRef,
  useState,
  ReactElement,
  ReactNode,
} from "react";
import Tippy from "@tippyjs/react/headless";
import { useCss, k, a } from "kremling";
import { Placement } from "tippy.js";

interface CpTooltipProps {
  children: ReactElement;
  delay?: number;
  disabled?: boolean;
  interactive?: boolean;
  position?:
    | Placement
    | "bottom-right"
    | "bottom-left"
    | "top-right"
    | "top-left"
    | "left-top"
    | "left-bottom"
    | "right-top"
    | "right-bottom";
  text: string | ReactNode;
}

export const CpTooltip = forwardRef<HTMLDivElement, CpTooltipProps>(
  (
    {
      children,
      delay = 200,
      disabled,
      interactive = false,
      position = "bottom",
      text,
    },
    ref,
  ) => {
    const scope = useCss(css);
    const [show, setShow] = useState(false);
    const unmountTimeoutRef = useRef<ReturnType<typeof setTimeout>>();

    const childIsFunction = typeof (children as any).type === "function";
    const placement = placementConversion(position);

    const hideOnPopperBlur = {
      name: "hideOnPopperBlur",
      defaultValue: true,
      fn(instance: any) {
        return {
          onCreate() {
            instance.popper.addEventListener("focusout", (event: any) => {
              if (
                instance.props.hideOnPopperBlur &&
                event.relatedTarget &&
                !instance.popper.contains(event.relatedTarget)
              ) {
                instance.hide();
              }
            });
          },
        };
      },
    };

    function onMount() {
      if (unmountTimeoutRef.current) {
        clearTimeout(unmountTimeoutRef.current);
      }
      setShow(true);
    }

    function onHide({ unmount }: any) {
      setShow(false);
      unmountTimeoutRef.current = setTimeout(unmount, 200);
    }

    if (disabled) return <>{children}</>;

    return (
      <Tippy
        allowHTML
        animation={true}
        onHide={onHide}
        onMount={onMount}
        maxWidth={240}
        delay={delay ? [delay, interactive ? 500 : 0] : undefined}
        placement={placement}
        zIndex={100001}
        ref={ref}
        render={(attrs) => {
          return (
            <div
              {...attrs}
              {...scope}
              className={a("cp-tooltip").m("cp-tooltip--show", show)}
              aria-expanded={show}
              tabIndex={-1}
            >
              {text}
              <div className="cp-tooltip-arrow" data-popper-arrow="" />
            </div>
          );
        }}
        interactive={interactive}
        plugins={[hideOnPopperBlur]}
      >
        {childIsFunction ? (
          <div style={{ display: "inline-block" }}>{children}</div>
        ) : (
          children
        )}
      </Tippy>
    );
  },
);

const css = k`
  .cp-tooltip {
    font-size: 1.2rem;
    background: var(--cp-color-tooltip-bg);
    color: var(--cp-color-tooltip-text);
    padding: .5rem 1rem;
    border-radius: var(--cp-form-border-radius);
    font-weight: 500;
    max-width: 28rem;
    opacity: 0;
    transition: opacity 200ms ease;
    overflow-wrap: break-word;
    word-wrap: break-word;
    hyphens: auto;
  }
  .cp-tooltip--show {
    opacity: 1;
  }
  @media screen and (max-width: 599px) {
    .cp-tooltip--show{
      opacity: 0;
    }
  }

  .cp-tooltip-arrow,
  .cp-tooltip-arrow::before {
    position: absolute;
    width: .8rem;
    height: .8rem;
    background: inherit;
  }

  .cp-tooltip-arrow {
    visibility: hidden;
  }

  .cp-tooltip-arrow::before {
    visibility: visible;
    content: '';
    transform: rotate(45deg);
  }

  .cp-tooltip[data-placement^='top'] > .cp-tooltip-arrow {
    bottom: -.4rem;
  }

  .cp-tooltip[data-placement^='bottom'] > .cp-tooltip-arrow {
    top: -.4rem;
  }

  .cp-tooltip[data-placement^='left'] > .cp-tooltip-arrow {
    right: -.4rem;
  }

  .cp-tooltip[data-placement^='right'] > .cp-tooltip-arrow {
    left: -.4rem;
  }
`;

function placementConversion(position: string): Placement {
  let placement: Placement;
  switch (position) {
    case "top-left":
      placement = "top-start";
      break;
    case "top-right":
      placement = "top-end";
      break;
    case "left-top":
      placement = "left-start";
      break;
    case "left-bottom":
      placement = "left-end";
      break;
    case "right-top":
      placement = "right-start";
      break;
    case "right-bottom":
      placement = "right-end";
      break;
    case "bottom-left":
      placement = "bottom-start";
      break;
    case "bottom-right":
      placement = "bottom-end";
      break;
    default:
      placement = position as Placement;
  }
  return placement;
}
