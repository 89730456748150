import React from "react";
import { useCss, k, a } from "kremling";

export type OverlayBodyProps = {
  children?: React.ReactNode;
  className?: string | undefined;
  onScroll?: React.UIEventHandler<HTMLDivElement>;
} & React.HTMLAttributes<HTMLDivElement>;

export function OverlayBody({
  children,
  className,
  onScroll,
  ...rest
}: OverlayBodyProps) {
  const scope = useCss(css);
  return (
    <div className={a("cp-overlay-body", className)} {...rest} {...scope}>
      <div className="cp-overlay-body__inner" onScroll={onScroll}>
        {children}
      </div>
    </div>
  );
}

const css = k`
  .cp-overlay-body {
    position: relative;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden;
    border-bottom-left-radius: .8rem;
    border-bottom-right-radius: .8rem;
  }

  .cp-overlay-body__inner {
    padding: 1.6rem;
    overflow: auto;
    flex-grow: 1;
  }
`;
