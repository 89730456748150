import { DateTime } from "luxon";

const defaultDateRanges = [
  "currentMonth",
  "lastMonth",
  "currentQuarter",
  "yearToDate",
  "custom",
];

const getDateRangeDefinition = (range) => {
  const now = DateTime.local();
  const today = now.toISODate();

  switch (range) {
    case "today":
      return {
        label: "Today",
        filter: {
          after: today,
          before: today,
        },
      };
    case "yesterday":
      return {
        label: "Yesterday",
        filter: {
          after: now.minus({ days: 1 }).toISODate(),
          before: now.minus({ days: 1 }).toISODate(),
        },
      };
    case "tomorrow":
      return {
        label: "Tomorrow",
        filter: {
          after: now.plus({ days: 1 }).toISODate(),
          before: now.plus({ days: 1 }).toISODate(),
        },
      };
    case "currentWeek":
      return {
        label: "Current week",
        filter: {
          after: now.startOf("week").toISODate(),
          before: now.endOf("week").toISODate(),
        },
      };
    case "lastWeek":
      return {
        label: "Last week",
        filter: {
          after: now.minus({ weeks: 1 }).startOf("week").toISODate(),
          before: now.minus({ weeks: 1 }).endOf("week").toISODate(),
        },
      };
    case "currentMonth":
      return {
        label: "Current month",
        filter: {
          after: now.startOf("month").toISODate(),
          before: now.endOf("month").toISODate(),
        },
      };
    case "lastMonth":
      return {
        label: "Last month",
        filter: {
          after: now.minus({ months: 1 }).startOf("month").toISODate(),
          before: now.minus({ months: 1 }).endOf("month").toISODate(),
        },
      };
    case "currentQuarter":
      return {
        label: "Current quarter",
        filter: {
          after: now.startOf("quarter").toISODate(),
          before: now.endOf("quarter").toISODate(),
        },
      };
    case "lastQuarter":
      return {
        label: "Last quarter",
        filter: {
          after: now.minus({ quarters: 1 }).startOf("quarter").toISODate(),
          before: now.minus({ quarters: 1 }).endOf("quarter").toISODate(),
        },
      };
    case "yearToDate":
      return {
        label: "Year-to-date",
        filter: {
          after: DateTime.local().startOf("year").toISODate(),
          before: today,
        },
      };
    case "lastYear":
      return {
        label: "Last year",
        filter: {
          after: now.minus({ years: 1 }).startOf("year").toISODate(),
          before: now.minus({ years: 1 }).endOf("year").toISODate(),
        },
      };
    case "past":
      return {
        label: "Past Due",
        filter: {
          before: today,
        },
      };
    case "custom":
      return {
        label: "Custom",
        filter: {
          after: today,
          before: today,
        },
      };
  }

  return null;
};

const rangeReducer = (acc, range) => {
  const dateRangeDefinition = getDateRangeDefinition(range);

  if (dateRangeDefinition) {
    acc[range] = dateRangeDefinition;
  }

  return acc;
};

export function getColumnProp(filterField, hideNoDateAdded, dateRanges) {
  const column = {
    filterType: "date-range",
    maxSortValue: "Newest",
    minSortValue: "Oldest",
    sortParam: filterField,
    dateRanges: {
      ...(!hideNoDateAdded && {
        noDateAdded: {
          label: "No date added",
          filter: {},
        },
      }),
      ...(dateRanges.length > 0
        ? dateRanges.reduce(rangeReducer, {})
        : defaultDateRanges.reduce(rangeReducer, {})),
    },
  };

  return column;
}
