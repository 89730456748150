import React from "react";

import { useGlobalBannerStore } from "@hooks";
import { CpButton } from "@components";
import { tw } from "../../utils";

export function CpGlobalBanner() {
  const { show, setShow } = useGlobalBannerStore();

  function refresh() {
    location.reload();
  }

  if (!show) return null;
  return (
    <div
      className={tw(
        "fixed top-0 left-0 z-[999999] right-0 h-10 bg-[var(--cp-color-table-row-selected-bg)] flex items-center justify-center text-[var(--cp-color-button-flat-text)] gap-2",
      )}
    >
      <div>
        Something’s changed! A refresh is required to keep things running
        smoothly.
      </div>
      <a
        className={tw(
          "underline text-[var(--cp-color-button-flat-text)] hover:no-underline",
        )}
        onClick={() => refresh()}
      >
        Refresh now
      </a>
      <div
        className={tw("absolute right-4 h-10 flex items-center justify-center")}
      >
        <CpButton icon="close-small" onClick={() => setShow(false)} />
      </div>
    </div>
  );
}
