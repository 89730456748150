import React, { ReactNode, CSSProperties, FC } from "react";
import { ModalContainerComponent } from "./modal-container.component";
import { ModalHeader } from "./components/modal-header.component";
import { ModalBody } from "./components/modal-body.component";
import { CpCard } from "../index";
import {
  modalWidthMax,
  modalWidthMin,
  forceRange,
  ModalContext as _ModalContext,
} from "./modal.utils";

type CpModalProps = {
  children?: ReactNode;
  className?: string;
  onAfterClose?: () => void;
  onAfterOpen?: () => void;
  onClose?: () => void;
  show?: boolean;
  styles?: CSSProperties;
  width?: number;
};

type CpModalComponent = FC<CpModalProps> & {
  Header: typeof ModalHeader;
  Body: typeof ModalBody;
  Footer: typeof CpCard.Footer;
};

export const CpModal: CpModalComponent = ({
  children,
  width = 424,
  styles,
  ...props
}) => {
  return (
    <ModalContainerComponent
      {...props}
      styles={{
        ...styles,
        width: `${forceRange(width, modalWidthMin, modalWidthMax) / 10}rem`,
        maxHeight: "calc(100vh - 6.4rem)",
      }}
      animRotateX={-2}
      animScale={0.9}
    >
      {children}
    </ModalContainerComponent>
  );
};

CpModal.Header = ModalHeader;
CpModal.Body = ModalBody;
CpModal.Footer = CpCard.Footer;

export const ModalContext = _ModalContext;
