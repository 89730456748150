import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { fromEvent } from "rxjs";
import { CpTooltip } from "@components";
import { hasSortOrFilter } from "./column.helper";
import { CpDateRangeSelector } from "./menu-content.component";

import styles from "./cp-table-header-menu.styles.css";

export const CpTableHeaderMenu = ({
  filterContext,
  column,
  getDynamicFilters,
  getFixedFilters,
  allowFilterSort,
  hideSortOptions,
  onCancel,
  menuShown,
  setColumnMenuShown,
}) => {
  const columnHeaderMenu = useRef();

  useEffect(() => {
    const clickSubscription = fromEvent(document, "click").subscribe((e) => {
      handlePageClick(e);
    });

    return () => clickSubscription.unsubscribe();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const resetFilters = (e) => {
    e.stopPropagation();
    filterContext.applyFilter(column.sortParam, {});
    setColumnMenuShown(false);
  };

  const handlePageClick = (e) => {
    if (
      columnHeaderMenu &&
      menuShown &&
      !e.clickedOnFourByThree &&
      !columnHeaderMenu.contains(e.target) &&
      !columnHeaderMenu.parentElement.contains(e.target) // check the th element as well
    ) {
      setColumnMenuShown(false);
    }
  };

  const columnFilter = allowFilterSort
    ? filterContext.filters[column.sortParam]
    : null;

  let menuStyles = {
    cursor: "default",
    color: "black",
    minWidth: "224px",
    top: "calc(100% + 8px)",
    left: "-12px",
    fontWeight: 400,
    overflow: "hidden",
  };

  if (!!column.menuAlignRight) {
    menuStyles.left = "auto";
    menuStyles.right = "10px";
  } else if (column.columnLabel === "Due Date") {
    menuStyles.left = "-190px";
  }

  return (
    <div
      ref={columnHeaderMenu}
      className={`cps-inline-block cps-animate-fade`}
      style={{ width: "100%" }}
    >
      <div
        className={`cps-dropdown ${menuShown ? "cps-open" : ""} ${
          styles.spreadContents
        } ${styles.columnHeaderText}`}
      >
        <div className={column.columnHeaderClass}>
          <span>{column.columnLabel}</span>
          {allowFilterSort && (
            <i
              className={`cps-icon cps-icon-sm-caret-${
                columnFilter?.order === "asc" && column?.filterType === "none"
                  ? "up"
                  : "down"
              } ${styles.centerIcon}`}
            />
          )}
        </div>
        {allowFilterSort && hasSortOrFilter(columnFilter) && (
          <CpTooltip text="Clear sort and filter">
            <div className="cps-btn-icon" onClick={resetFilters}>
              <a className="cps-link" style={{ marginTop: 0 }}>
                <span className="cps-icon cps-icon-sm-neg" />
              </a>
            </div>
          </CpTooltip>
        )}
        {menuShown && (
          <CpDateRangeSelector
            filterContext={filterContext}
            column={column}
            getDynamicFilters={getDynamicFilters}
            getFixedFilters={getFixedFilters}
            hideSortOptions={hideSortOptions}
            onCancel={onCancel}
            setColumnMenuShown={setColumnMenuShown}
            menuStyles={menuStyles}
          />
        )}
      </div>
    </div>
  );
};

CpTableHeaderMenu.propTypes = {
  // filterContext should contain a filters array as well as applyFilter and clearFilters callback functions
  // filters is indexed by column.sortParam
  // applyFilter callback passes the column key and the value of the filter
  filterContext: PropTypes.object.isRequired,
  // column contains details about the table column that the filter applies to
  // column has
  // - sortParam (main key)
  // - filterName (returned the dynamic filter callback for identification)
  // - filterType (one type from filterTypes in column.helper.js)
  // - filterValues (static list of filters for fixed filter type)
  // - columnHeaderClass (custom class that is applied to the header)
  // - columnLabel (label for the table column header)
  // - showFilterList (true to show a list of filters (fixed/dynamic only))
  // - showSearch (true to show a text box for search entry)
  // - searchPlaceholder (placeholder text to put in the search box)
  // - minSortValue (label for sorting (i.e. Oldest or A))
  // - maxSortValue (label for sorting (i.e. Newest or Z))
  // - dateRanges (array of date ranges to override defaults (date range filter only))
  // - customFilter (react component to show in filter menu (custom filter only))
  column: PropTypes.object.isRequired,
  // callback to retrieve a list of filter options for the dynamic type
  getDynamicFilters: PropTypes.func,
  // callback to retrieve a list of filter options for the fixed type
  getFixedFilters: PropTypes.func,
  // true allows sorting/filtering
  allowFilterSort: PropTypes.bool,
  // true to show the menu, false to hide
  menuShown: PropTypes.bool,
  // callback to show/hide the menu
  setColumnMenuShown: PropTypes.func.isRequired,
  // callback evoked when user closes the menu via cancel link
  onCancel: PropTypes.func,
};
