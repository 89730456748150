import React, { createContext } from "react";
import { CheckboxGroupVal } from "./cp-checkbox-group";

export type CpCheckboxGroupContextType = {
  value?: (string | Record<string | number, unknown>)[];
  disabled?: boolean;
  onBlur?: React.FocusEventHandler;
  onChange?: (val: CheckboxGroupVal) => void;
};

export const CpCheckboxGroupContext = createContext<CpCheckboxGroupContextType>(
  {},
);
