import React from "react";
import styles from "./cp-label.styles.css";
import { m } from "kremling";

type CpLabelProps = {
  children?: React.ReactNode;
  className?: string;
  required?: boolean;
  inline?: boolean;
  error?: boolean;
} & React.LabelHTMLAttributes<HTMLLabelElement>;

export function CpLabel(props: CpLabelProps) {
  const { children, className, required, inline, error, ...rest } = props;
  return (
    <label
      {...rest}
      className={m("cp-color-app-error-text", error)
        .m(styles.inline, inline)
        .a(className)
        .a("cp-label")}
    >
      {children}
      {required && <span className="cp-color-app-primary"> *</span>}
    </label>
  );
}
