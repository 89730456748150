import React, {
  forwardRef,
  ReactNode,
  FocusEventHandler,
  ChangeEvent,
} from "react";
import { useCss, a } from "kremling";

export type CpCheckboxProps = {
  checked?: boolean | "indeterminate";
  children?: ReactNode;
  className?: string;
  disabled?: boolean;
  onBlur?: FocusEventHandler<HTMLInputElement>;
  onChange?: (value: boolean, event: React.ChangeEvent) => void;
  style?: object;
};

export const CpCheckbox = forwardRef<HTMLLabelElement, CpCheckboxProps>(
  function CpCheckbox(props, ref) {
    const {
      checked,
      children,
      className,
      disabled,
      onBlur,
      onChange,
      style,
      ...rest
    } = props;
    const scope = useCss(css);

    const handleChange = (
      value: boolean,
      event: ChangeEvent<HTMLInputElement>,
    ) => {
      if (checked === "indeterminate") {
        onChange?.(false, event);
      } else {
        onChange?.(value, event);
      }
    };

    return (
      <label
        {...scope}
        ref={ref}
        style={style}
        className={a("cp-checkbox")
          .a(className)
          .m("cp-checkbox--active", !!checked)
          .m("cp-checkbox--disabled", disabled)
          .m("cp-checkbox--checked", checked === true)
          .m("cp-checkbox--indeterminate", checked === "indeterminate")}
      >
        <input
          type="checkbox"
          onBlur={onBlur}
          onChange={(e) => handleChange(e.target.checked, e)}
          checked={checked === true}
          disabled={disabled}
          {...rest}
        />
        <div className="cp-checkbox__indicator">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 100 82.2"
            className="cp-checkbox__indicator-check-icon"
          >
            <path
              className="st0"
              d="M39.3,52.5L78.1,4.6c4.3-5.3,12.1-6.1,17.3-1.8c5.3,4.3,6.1,12.1,1.8,17.3L50.7,77.6 c-4.3,5.3-12.1,6.1-17.3,1.8L4.6,56.1c-5.3-4.3-6.1-12.1-1.8-17.3C7,33.5,14.8,32.7,20.1,37L39.3,52.5z"
            />
          </svg>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 100 24"
            className="cp-checkbox__indicator-indeterminate-icon"
          >
            <path
              className="st0"
              d="M88,24H12C5.4,24,0,18.6,0,12v0C0,5.4,5.4,0,12,0l76,0c6.6,0,12,5.4,12,12v0C100,18.6,94.6,24,88,24z"
            />
          </svg>
        </div>
        <div className="cp-checkbox__label">{children}</div>
      </label>
    );
  },
);

// language=css
const css = `
  .cp-checkbox--inline {
    display: flex;
    flex-wrap: wrap;
  }
  .cp-checkbox {
    display: flex;
    align-items: flex-start;
    justify-content: start;
    position: relative;
    cursor: pointer;
    user-select: none;
  }

  .cp-checkbox + .cp-checkbox {
    padding-top: .4rem;
  }

  .cp-checkbox--inline .cp-checkbox {
    margin-right: 2.4rem;
  }

  .cp-checkbox input {
    position: absolute;
    width: 1.7rem;
    height: 1.8rem;
    margin: 0.1rem 0;
    opacity: 0;
    cursor: pointer;
  }

  .cp-checkbox input:disabled {
    cursor: default;
  }

  .cp-checkbox__indicator {
    width: 1.7rem;
    height: 1.7rem;
    display: flex;
    flex-shrink: 0;
    margin-top: .15rem;
    align-items: center;
    justify-content: center;
    border: solid .2rem var(--cp-color-checkbox-border);
    border-radius: var(--cp-form-border-radius);
    margin-right: .8rem;
    overflow: hidden;
    left: 0;
    transition:
      background-color var(--cp-form-transition-duration) ease,
      border-color var(--cp-form-transition-duration) ease,
      box-shadow var(--cp-form-transition-duration) ease;
  }

  .cp-checkbox__indicator-check-icon,
  .cp-checkbox__indicator-indeterminate-icon {
    opacity: 0;
    position: absolute;
    fill: var(--cp-color-checkbox-selected-icon);
    width: .9rem;
  }

  .cp-checkbox__label {
    font-size: 1.4rem;
  }

  .cp-checkbox:focus-within .cp-checkbox__indicator {
    box-shadow: var(--cp-form-focus-state);
  }

  .cp-checkbox--active .cp-checkbox__indicator {
    border-color: var(--cp-color-checkbox-selected-bg);
    background-color: var(--cp-color-checkbox-selected-bg);
  }

  .cp-checkbox--checked .cp-checkbox__indicator-check-icon,
  .cp-checkbox--indeterminate .cp-checkbox__indicator-indeterminate-icon {
    opacity: 1;
  }

  .cp-checkbox--disabled {
    cursor: default;
  }

  .cp-checkbox--disabled .cp-checkbox__indicator {
    border-color: #afafaf;
  }

  .cp-checkbox--disabled.cp-checkbox--active .cp-checkbox__indicator {
    background-color: #afafaf;
  }

  .cp-checkbox--disabled .cp-checkbox__label {
    color: var(--cp-color-app-secondary-text);
  }
`;
