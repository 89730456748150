import React, { useContext, ReactNode } from "react";
import { useCss, k } from "kremling";

import { CpButton, CpHeader, CpTooltip } from "@components";
import { ModalContext } from "../../cp-modal/modal.utils";

export type OverlayHeaderProps = {
  title: string | React.ReactNode;
  children?: ReactNode;
  [key: string]: any;
};

export const OverlayHeader: React.FC<OverlayHeaderProps> = ({
  title,
  children,
  ...rest
}) => {
  const { onClose } = useContext(ModalContext);
  const scope = useCss(styles);
  return (
    <>
      <CpHeader {...rest}>
        <CpTooltip text={title}>
          <div className="overlay-header-title cp-ellipsis" {...scope}>
            {title}
          </div>
        </CpTooltip>
        <div className="overlay-header-actions" {...scope}>
          <div className="cp-mr-16">{children}</div>
          <div className="overlay-header-divider" />
          <CpButton icon="close-small" aria-label="close" onClick={onClose} />
        </div>
      </CpHeader>
    </>
  );
};

const styles = k`
  .overlay-header-divider {
    height: 3.2rem;
    width: .1rem;
    background-color: var(--cps-color-border);
    margin-right: .8rem;
  }
  .overlay-header-title {
    font-size: 1.8rem;
    font-weight: 500;
  }
  .overlay-header-actions {
    display: flex;
    align-items: center;
    margin-right: -.4rem;
    min-width: fit-content;
  }
`;
