import React, { useContext, ReactNode, HTMLAttributes } from "react";
import { useCss, k } from "kremling";

import { CpButton, CpHeader } from "../../index";
import { ModalContext } from "../modal.utils";

type ModalHeaderProps = HTMLAttributes<HTMLDivElement> & {
  title: string;
  children?: ReactNode;
  onClose?: () => void;
};

export const ModalHeader: React.FC<ModalHeaderProps> = ({
  title,
  children,
  onClose: propOnClose,
  ...rest
}) => {
  const { onClose } = useContext(ModalContext);
  const scope = useCss(styles);
  return (
    <CpHeader {...rest}>
      <div className="modal-header-title" {...scope}>
        {title}
      </div>
      <div className="modal-header-actions" {...scope}>
        <div className="cp-mr-16">{children}</div>
        <CpButton
          icon="close-small"
          aria-label="close"
          onClick={propOnClose || onClose}
        />
      </div>
    </CpHeader>
  );
};

const styles = k`
  .modal-header-title {
    font-size: 1.8rem;
    font-weight: 500;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  .modal-header-actions {
    display: flex;
    align-items: center;
    margin-right: -.4rem;
  }
`;
