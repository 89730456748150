// Global css
import "./styles/tailwind.scss";
import "./global-css/global-css.less";
import "./styles/main.scss";
import "@webcomponents/custom-elements";
import "./custom-elements/definitions.js";
import "./scripts";

export * from "./custom-elements";
export * from "./components";
export * from "./helpers";
export * from "./hooks";
export * from "./prop-types";

// add Inter font to head
const el = document.createElement("link");
el.setAttribute("rel", "stylesheet");
el.setAttribute("href", "https://cdn.canopytax.com/static/Inter/inter.css");
el.setAttribute("type", "text/css");
const head = document.getElementsByTagName("head")[0];
head.appendChild(el);
