import React from "react";
import { useCss, a } from "kremling";

import { CpCheckboxGroupContext } from "./cp-checkbox-group-context";
import { CpCheckboxGroupItem } from "./cp-checkbox-group-item";

export type CheckboxGroupValObj = Record<string, unknown>;
export type CheckboxGroupVal = string | CheckboxGroupValObj;

export type CpCheckboxGroupProps = {
  children?: React.ReactNode;
  className?: string;
  disabled?: boolean;
  inline?: boolean;
  onBlur?: React.FocusEventHandler;
  onChange?: (value: any[]) => void;
  value?: CheckboxGroupVal[];
};

type CpCheckboxGroupComponent = React.FC<CpCheckboxGroupProps> & {
  Item: typeof CpCheckboxGroupItem;
};

export const CpCheckboxGroup: CpCheckboxGroupComponent = (
  props: CpCheckboxGroupProps,
) => {
  const { children, className, disabled, inline, onBlur, onChange, value } =
    props;
  const scope = useCss(css);

  function update(delta: CheckboxGroupVal) {
    let newValues;
    if (typeof delta === "object") {
      const values = (value as CheckboxGroupValObj[])?.filter(
        (val) => val.id !== delta.id,
      );
      newValues =
        values?.length === value?.length ? [...(value || []), delta] : values;
    } else {
      newValues = value?.includes(delta)
        ? value.filter((v) => v !== delta)
        : [...(value || []), delta];
    }
    if (newValues) {
      onChange?.(newValues);
    }
  }
  return (
    <CpCheckboxGroupContext.Provider
      value={{ value, onChange: update, onBlur, disabled }}
    >
      <div
        {...scope}
        className={a("cp-checkbox-group")
          .a(className)
          .m("cp-checkbox--inline", inline)}
      >
        {children}
      </div>
    </CpCheckboxGroupContext.Provider>
  );
};

CpCheckboxGroup.Item = CpCheckboxGroupItem;

// language=css
const css = `
  .cp-checkbox--inline {
    display: flex;
    flex-wrap: wrap;
  }

  .cp-checkbox--inline .cp-checkbox {
    margin-right: 2.4rem;
    margin-bottom: .4rem;
  }

  .cp-checkbox--inline .cp-checkbox + .cp-checkbox {
    padding: 0;
  }
`;
