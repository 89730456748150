import React from "react";
import { useCss, a } from "kremling";

import { CpCard, CpEmptyState } from "@components";

type CpEmptyStateCardProps = {
  className?: string;
  img: string;
  [key: string]: any;
};

export function CpEmptyStateCard({
  className,
  ...props
}: CpEmptyStateCardProps) {
  const scope = useCss(styles);
  const { img, ...rest } = props;
  return (
    <CpCard {...scope} className={a("cp-empty-state-card", className)}>
      <CpEmptyState img={img} {...rest} />
    </CpCard>
  );
}

const styles = `
  .cp-empty-state-card {
    padding: 3.2rem;
    max-width: 46.4rem;
    margin-left: auto;
    margin-right: auto;
  }
`;
