import React from "react";

import {
  ModalContainerComponent,
  ModalContainerComponentProps,
} from "../cp-modal/modal-container.component";
import { smallOverlayMax } from "../cp-modal/modal.utils";
import {
  OverlayHeader,
  OverlayHeaderProps,
} from "./components/overlay-header.component";
import {
  OverlayBody,
  OverlayBodyProps,
} from "./components/overlay-body.component";

type CpOverlayProps = Omit<
  ModalContainerComponentProps,
  "animRotateX" | "animScale"
> & {
  small?: boolean;
};

export interface CpOverlayComponent extends React.FC<CpOverlayProps> {
  Header: React.FC<OverlayHeaderProps>;
  Body: React.FC<OverlayBodyProps>;
}

export const CpOverlay = Object.assign(
  ({ children, styles, small, ...props }: CpOverlayProps) => {
    const maxWidth = small ? { maxWidth: `${smallOverlayMax}px` } : {};
    return (
      <ModalContainerComponent
        {...props}
        styles={{
          ...styles,
          width: "calc(100% - 6.4rem)",
          height: "calc(100% - 6.4rem)",
          ...maxWidth,
        }}
        animRotateX={-1}
        animScale={0.92}
      >
        {children}
      </ModalContainerComponent>
    );
  },
  { Header: OverlayHeader, Body: OverlayBody },
);
