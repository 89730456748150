import React, { useCallback, useEffect, useRef } from "react";
import { useCss, a, m } from "kremling";
import { func, string, oneOf, number, object } from "prop-types";
import { CpLabel } from "@components";
import { useMergeRefs } from "@floating-ui/react";

export const CpTextarea = React.forwardRef(function CpTextarea(props, ref) {
  const {
    className,
    error,
    height,
    label,
    maxHeight,
    maxWidth,
    onChange,
    resize = "auto",
    style,
    width,
    containerProps,
    ...rest
  } = props;
  const message = typeof props.error === "string" ? props.error : props.message;

  const textareaRef = useRef(null);
  const mergedRef = useMergeRefs([ref, textareaRef]);

  const scope = useCss(css);

  const adjustHeight = useCallback(() => {
    textareaRef.current.style.height = "auto";
    textareaRef.current.style.height = textareaRef.current.scrollHeight + "px";
  }, []);

  useEffect(() => {
    if (resize !== "auto") return;
    adjustHeight();
  }, [props.value, resize, adjustHeight]);

  return (
    <div
      {...scope}
      className={m("cp-textarea--error", error)}
      {...containerProps}
    >
      {label && (
        <CpLabel htmlFor={rest?.id} required={rest?.required} error={error}>
          {label}
        </CpLabel>
      )}
      <div>
        <textarea
          className={a("cp-textarea").a(className)}
          rows={1}
          {...rest}
          style={{
            resize: resize !== "auto" ? resize : "none",
            minHeight: height || "100%",
            minWidth: width || "4rem",
            maxHeight: resize && maxHeight,
            maxWidth: resize && maxWidth,
            ...style,
          }}
          ref={mergedRef}
          onChange={(e) => {
            onChange(e.target.value);
          }}
        />
        {message && <div className="cp-textarea-message">{message}</div>}
      </div>
    </div>
  );
});

CpTextarea.propTypes = {
  value: string.isRequired,
  onChange: func.isRequired,
  height: number,
  width: number,
  maxHeight: number,
  maxWidth: number,
  containerProps: object,
  resize: oneOf(["auto", "none", "both", "horizontal", "vertical"]),
};

// language=css
const css = `
  .cp-textarea {
    box-sizing: border-box;
    width: 100%;
    border: 1px solid var(--cps-color-border);
    border-radius: .4rem;
    padding: .4rem 1.2rem;
    transition: box-shadow var(--cp-form-transition-duration) ease;
    background-color: var(--cps-color-light-well);
  }
  .cp-textarea:focus-visible,
  .cp-textarea:focus-within {
    border-color: var(--cps-color-primary);
    box-shadow: var(--cp-form-focus-state);
    outline: none;
  }
  .cp-textarea:focus-visible .cp-icon,
  .cp-textarea:focus-within .cp-icon {
    color: var(--cps-color-primary);
  }

  .cp-textarea--error textarea {
    border-color: var(--cp-color-input-error-border);
  }

  .cp-textarea--error textarea:focus {
    box-shadow: var(--cp-form-focus-state-error);
  }
  .cp-textarea--error .cp-textarea-message {
    color: var(--cp-color-app-error-text);
  }

  .cp-textarea-message {
    color: var(--cp-color-app-secondary-text);
    font-size: 1.2rem;
    margin-top: .4rem;
  }
`;
