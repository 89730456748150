import React, { createContext, useContext } from "react";
import { useCss, a } from "kremling";
import { bool, func, string, any } from "prop-types";

const CpRadioContext = createContext({});

export function CpRadio(props) {
  const {
    children,
    disabled,
    error,
    inline,
    name,
    onBlur,
    onChange,
    value,
    className,
  } = props;
  const scope = useCss(css);
  return (
    <CpRadioContext.Provider
      value={{ value, onBlur, onChange, disabled, name }}
    >
      <div {...scope} className={a(className).m("cp-radio--inline", inline)}>
        {children}
      </div>
      {!!error && <div className="cp-radio__error">{error}</div>}
    </CpRadioContext.Provider>
  );
}

CpRadio.propTypes = {
  disabled: bool,
  error: string,
  inline: bool,
  name: string.isRequired,
  onBlur: func,
  onChange: func.isRequired,
  value: any,
};

const RadioItem = ({ children, id, style, className }) => {
  const { value, disabled, onBlur, onChange, name } =
    useContext(CpRadioContext);
  const active = id === value;
  return (
    <label
      style={style}
      className={a("cp-radio")
        .a(className)
        .m("cp-radio--active", active)
        .m("cp-radio--disabled", disabled)}
    >
      <input
        name={name}
        type="radio"
        value={value || ""}
        onBlur={onBlur}
        onChange={() => onChange(id)}
        checked={active}
        disabled={disabled}
      />
      <div className={a("cp-radio__indicator")} />
      <div className="cp-radio__label">{children}</div>
    </label>
  );
};

CpRadio.Item = RadioItem;

// language=css
const css = `
  .cp-radio--inline {
    display: flex;
    flex-wrap: wrap;
  }
  .cp-radio {
    display: flex;
    align-items: center;
    justify-content: start;
    position: relative;
    cursor: pointer;
    user-select: none;
  }

  .cp-radio + .cp-radio {
    padding-top: .4rem;
  }

  .cp-radio--inline .cp-radio + .cp-radio {
    padding: 0;
  }

  .cp-radio--inline .cp-radio {
    margin-right: 2.4rem;
  }

  .cp-radio input {
    position: fixed;
    top: -999999px;
  }

  .cp-radio__indicator {
    width: 1.6rem;
    min-width: 1.6rem;
    height: 1.6rem;
    border: solid .2rem #afafaf;
    border-radius: 1.6rem;
    margin-right: .8rem;
    transition:
      border-width 100ms ease,
      border-color var(--cp-form-transition-duration) ease,
      box-shadow var(--cp-form-transition-duration) ease;
  }

  .cp-radio__label {
    font-size: 1.4rem;
  }

  .cp-radio:focus-within .cp-radio__indicator {
    box-shadow: var(--cp-form-focus-state);
  }

  .cp-radio--active .cp-radio__indicator {
    border-width: .5rem;
    border-color: var(--cp-color-radio-selected-bg);
  }

  .cp-radio--disabled {
    cursor: default;
  }

  .cp-radio--disabled .cp-radio__indicator {
    border-color: #afafaf;
  }

  .cp-radio--disabled .cp-radio__label {
    color: var(--cp-color-app-secondary-text);
  }

  .cp-radio__error {
    padding-top: .8rem;
    color: var(--cp-color-app-error);
  }
`;
