import { create } from "zustand";

type GlobalBannerState = {
  setShow: (show: boolean) => void;
  show: boolean;
};

export const useGlobalBannerStore = create<GlobalBannerState>()((setState) => ({
  setShow: (show: boolean) => setState({ show }),
  show: false,
}));
