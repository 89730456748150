import React, { Children, isValidElement, forwardRef } from "react";
import { string, number, bool, func, oneOfType } from "prop-types";
import { useCss, k, a } from "kremling";
import { isUndefined } from "lodash";

export const CpTabs = forwardRef((props, ref) => {
  const {
    activeId,
    block,
    border,
    children,
    dark,
    onChange,
    className,
    ...rest
  } = props;
  const scope = useCss(css);

  const otherChildren = Children.map(children, (child) => {
    return isValidElement(child)
      ? React.cloneElement(child, { onChange, activeId, dark, block })
      : child;
  });

  return (
    <div
      {...scope}
      {...rest}
      ref={ref}
      className={a("cp-tabs").a(className).m("cp-tabs-block", block)}
    >
      <div
        className={a("cp-tabs-children")
          .m("cp-tabs-block", block)
          .m("cp-tabs-children--padding", border)}
      >
        {otherChildren}
      </div>
      {border && (
        <div
          {...scope}
          className={a("cp-tabs-border").m("cp-tabs-border--dark", dark)}
        />
      )}
    </div>
  );
});

CpTabs.propTypes = {
  activeId: oneOfType([string, number]),
  block: bool,
  border: bool,
  dark: bool,
  onChange: func,
};

/** Tab button **/
CpTabs.Button = function ({
  activeId,
  block,
  children,
  dark,
  id,
  notification,
  onChange,
  itemCount = 0,
  ...rest
}) {
  const isActive = !isUndefined(id) && activeId === id;

  return (
    <button
      className={a("cp-tab")
        .m("active-tab", isActive && !dark)
        .m("active-tab-dark", isActive && dark)
        .m("dark-tab", dark)
        .m("cp-tab-block", block)
        .m("cp-tab-notification", notification)}
      onClick={() => onChange(id)}
      {...rest}
    >
      {children}
      {itemCount > 0 && (
        <span className="cp-tab-item-count">({itemCount})</span>
      )}
    </button>
  );
};

/** Anchor Tab **/
CpTabs.Anchor = function ({
  activeId,
  block,
  children,
  dark,
  id,
  notification,
  onChange = () => {},
  itemCount = 0,
  ...rest
}) {
  const isActive = id && activeId === id;

  return (
    <a
      className={a("cp-tab")
        .m("active-tab", isActive && !dark)
        .m("active-tab-dark", isActive && dark)
        .m("dark-tab", dark)
        .m("cp-tab-block", block)
        .m("cp-tab-notification", notification)}
      {...rest}
      onClick={() => onChange(id)}
    >
      {children}
      {itemCount > 0 && (
        <span className="cp-tab-item-count">({itemCount})</span>
      )}
    </a>
  );
};

const css = k`
  .cp-tabs {
    display: flex;
    flex-direction: column;
    align-self: flex-end;
    white-space: nowrap;
  }

  .cp-tabs-children {
    display: flex;
  }

  .cp-tabs-border {
    height: 1px;
    background: var(--cps-color-border);
    width: 100%;
  }

  .cp-tabs-border--dark {
    background-color: rgba(var(--cps-color-light-primary-text-rgb), .3);
  }

  .cp-tab {
    color: var(--cps-color-secondary-text);
    padding: 0 1.2rem;
    font-weight: bold;
    cursor: pointer;
    text-align: center;
    position: relative;
    height: 4rem;
    transition: box-shadow 200ms ease;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }

  .cp-tab:focus {
    box-shadow: var(--cp-form-focus-state);
    outline: none;
  }

  .cp-tabs button:hover,
  .cp-tabs button:focus {
    padding: 0 1.2rem;
  }

  .cp-tab:hover {
    background-color: #F4F5F8;
    text-decoration: none;
  }

  .cp-tab-notification:before {
    content: "";
    position: absolute;
    background: var(--cp-color-notification-badge-bg);
    border-radius: 50%;
    width: .8rem;
    height: .8rem;
    top: 1rem;
    left: .8rem;
  }

  button.cp-tab {
    border: none;
    background: transparent;
  }

  .active-tab {
    color: var(--cps-color-primary-text);
  }

  .active-tab::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    color: var(--cps-color-primary-text);
    height: .3rem;
    background-color: var(--cps-color-primary);
  }

  .dark-tab {
    color: rgba(var(--cps-color-light-primary-text-rgb), .8);
  }

  .cp-tab.dark-tab:focus {
    box-shadow: var(--cp-form-focus-state-dark);
    outline: none;
  }

  .active-tab-dark {
    color: var(--cps-color-light-primary-text);
  }

  .active-tab-dark::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    border-bottom: .3rem solid #fff;
  }

  .dark-tab:hover {
    background-color: rgba(var(--cps-color-light-primary-text-rgb), .1);
    text-decoration: none;
  }

  .cp-tabs-block  {
    width: 100%;
  }

  .cp-tabs-children--padding {
    padding-left: .8rem;
    padding-right: .8rem;
  }

  .cp-tab-block {
    flex-grow: 1;
  }

  .cp-tab-item-count {
    margin-left: .8rem;
    color: var(--cp-color-app-disabled-text);
  }

  .active-tab > .cp-tab-item-count {
    color: var(--cp-color-primary-text);
  }
`;
