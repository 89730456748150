import React from "react";
import { CpCheckboxGroup } from "@components";
import { useController } from "react-hook-form";
import { any, object, string } from "prop-types";
import { CpCheckboxGroupItem } from "../cp-checkbox-group-item";

type CpCheckboxGroupFieldProps = {
  control: any;
  controllerOptions?: object;
  fieldName: string;
  rules?: object;
  [key: string]: any;
};

type CpCheckboxGroupFieldComponent = React.FC<CpCheckboxGroupFieldProps> & {
  Item: typeof CpCheckboxGroupItem;
};

export const CpCheckboxGroupField: CpCheckboxGroupFieldComponent = (
  props: CpCheckboxGroupFieldProps,
) => {
  const { fieldName, control, controllerOptions, rules, ...cpToggleProps } =
    props;
  const {
    field: { value, onBlur, onChange },
  } = useController({ name: fieldName, control, rules, ...controllerOptions });

  return (
    <CpCheckboxGroup
      {...cpToggleProps}
      value={value}
      onBlur={onBlur}
      onChange={onChange}
    />
  );
};

CpCheckboxGroupField.Item = CpCheckboxGroup.Item;

CpCheckboxGroupField.propTypes = {
  control: any.isRequired,
  controllerOptions: object,
  fieldName: string.isRequired,
  rules: object,
};
