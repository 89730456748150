import React, { useEffect, useState } from "react";
import { isEmpty } from "lodash";
import isEmail from "validator/lib/isEmail";
import { CpInput } from "@components";

type EmailProps = {
  isValid?: boolean;
  setIsValid?: (isValid: boolean) => void;
  value?: string;
  error?: string;
  [key: string]: any;
};

export function Email(props: EmailProps) {
  const [isValidDefault, setIsValidDefault] = useState(true);

  const {
    isValid = isValidDefault,
    setIsValid = setIsValidDefault,
    value = "",
    error,
    ...inputProps
  } = props;

  useEffect(() => {
    setIsValid(isEmpty(value) || isEmail(value));
  }, [value, setIsValid]);

  return (
    <CpInput
      error={!isValid && (error || "Invalid email")}
      value={value}
      {...inputProps}
    />
  );
}
