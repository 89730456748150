import React, { useEffect, useState } from "react";
import { isEmpty } from "lodash";
import isURL from "validator/lib/isURL";

import { CpInput } from "@components";

type UrlProps = {
  isValid?: boolean;
  setIsValid?: (valid: boolean) => void;
  value?: string;
  [key: string]: any;
};

export function Url(props: UrlProps) {
  const [isValidDefault, setIsValidDefault] = useState(true);

  const {
    isValid = isValidDefault,
    setIsValid = setIsValidDefault,
    value,
    ...inputProps
  } = props;

  useEffect(() => {
    setIsValid(isEmpty(value) || isURL(value || ""));
  }, [value, setIsValid]);

  return (
    <CpInput error={!isValid && "Invalid URL"} value={value} {...inputProps} />
  );
}
