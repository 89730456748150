import React from "react";
import { useCss, a, k } from "kremling";

type CpTogglePillProps = {
  text: string;
  active: boolean;
  className?: string;
  [key: string]: any;
};

export function CpTogglePill(props: CpTogglePillProps) {
  const { active, text, className, ...rest } = props;

  const scope = useCss(css);

  return (
    <button
      {...scope}
      className={a("pill").t("active", "inactive", active).a(className)}
      {...rest}
    >
      <span className="pill__inner">{text}</span>
    </button>
  );
}

const css = k`
.pill {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: .2rem .8rem .2rem .8rem;
  border-radius: .8rem;
  font-size: 1.3rem;
  width: fit-content;
  height: 2.4rem;
  user-select: none;
  position: relative;
  cursor: pointer;
  background-color: var(--cp-color-pill-button-bg);

  span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
.pill::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #000;
  opacity: 0;
  transition: opacity var(--cp-form-transition-duration) ease;
}

.pill:hover {
  text-decoration: none;
}

.pill:hover::after {
  opacity: .07;
}

.pill:active::after {
  opacity: .1;
}

.pill:active, .pill:focus {
  outline: none;
  box-shadow: none;
}

.pill:focus:not(:active) {
  box-shadow: var(--cp-form-focus-state);
}

.pill.pill--disabled {
  cursor: default;
}

.pill.pill--disabled::after {
  display: none;
}

.pill.active {
  border: solid .1rem var(--cp-color-toggle-pill-active-border);
  background-color: var(--cp-color-toggle-pill-active-bg);
  color: var(--cp-color-toggle-pill-active-text);
}

.pill.inactive {
  border: solid .1rem var(--cp-color-toggle-pill-inactive-border);
  color: var(--cp-color-app-primary-text);
}

.pill__inner {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  align-items: center;
}
`;
